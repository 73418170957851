import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Title extends Component {
  render () {
    return (
        <div className="application">
          <Helmet>
            <title>Vasudevan Suriyababu - Homepage</title>
            <meta name="description" content="Vasudevan Suriyababu - Homepage" />
            <meta charSet="utf-8" />
          </Helmet>
          {/* ... */}
        </div>
    );
  }
};

export default Title;
