import React from 'react';
import {Container, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import Experience from "./Experience";
import Education from './Education';
import Technologies from './Technology';
import Hobbies from './Hobbies';
import profile from '../profile.json';
import profilePic from '../IMG-0874.png';

const title = "Vasudevan Suriyababu - Homepage"

class Profile extends React.Component {
  componentDidMount() {
    document.title = title;
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  // <Jumbotron class="jumbotron" fluid>
  // </Jumbotron>

  render() {
    return ( <div id="parent"><div id="profile-info" class="profile_page">
        <Container class="container">
              <br/>
              <img src={profilePic} className="avatar" alt="Vasudevan Suriyababu"/>
              <br/>
              <h1 align="center"><a style={{ color: "black" }} href="https://bio.vasudevansuriyababu.page" title="Vasudevan Suriyababu">Vasudevan Suriyababu</a></h1>
              <br/>
              <h3 align="center">{profile.title} - {profile.company}</h3>
              <br/>
              <h4 align="center">{profile.summary}</h4>
              <br/><br/>
        </Container>
      </div>
      <div>
      <Container>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                     onClick={() => { this.toggle('1'); }}>
              Experience
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                     onClick={() => { this.toggle('2'); }}>
              Education
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === '3' })}
                     onClick={() => { this.toggle('3'); }}>
              Technologies
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === '4' })}
                     onClick={() => { this.toggle('4'); }}>
              Hobbies
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Experience/>
          </TabPane>
          <TabPane tabId="2">
            <Education/>
          </TabPane>
          <TabPane tabId="3">
            <Technologies/>
          </TabPane>
          <TabPane tabId="4">
            <Hobbies/>
          </TabPane>
        </TabContent>
      </Container>
    </div></div>)
  }
}

export default Profile;
