import React, { Component } from 'react';

import './App.css';

import Title from './components/Title';
import TopNav from './components/TopNav';
import Profile from './components/Profile';
import Footer from './components/Footer';

const title = "Vasudevan Suriyababu - HomePage"

class App extends Component {
  componentDidMount() {
    document.title = title;
  }

  render() {
    return (
      <div className="root">
        <Title />
        <TopNav />
        <Profile />
        <Footer />
      </div>
    );
  }
}

export default App;
