import React from 'react';
import { Container } from 'reactstrap';
import profile from '../profile.json';
import '../hobbies.css';

class Hobbies extends React.Component {
  render() {
    return <Container>
      {
        <div>
          <br/>
          <br/>
          <table id='hobbies'>
            <tbody>
            <tr>
              <td><b>What</b></td>
              <td><b>Specifics</b></td>
            </tr>
            {
              profile.hobbies.map(
                function (hobby, i) {
                  return <tr key={i}>
                    <td>{hobby.item}</td>
                    <td>{hobby.stuff}</td>
                  </tr>
                }
              )
            }
            </tbody>
          </table>
        </div>
      }
      </Container>
  }
}

export default Hobbies;
