import React from 'react';
import {Container} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faFacebook, faTwitter, faPinterest, faYelp, faBitbucket } from '@fortawesome/free-brands-svg-icons';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <hr/>
        <Container>
          <p align="center" color="#808080">SOCIAL MEDIA</p>
          <a href="https://www.linkedin.com/in/vasudevansuriyababu"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://www.facebook.com/vasudevansuriyababu"><FontAwesomeIcon icon={faFacebook}  /></a>
          <a href="https://twitter.com/VasudevanY"><FontAwesomeIcon icon={faTwitter}  /></a>
          <a href="https://github.com/vasudevan-suriyababu"><FontAwesomeIcon icon={faGithub}  /></a>
          <a href="https://bitbucket.org/%7B02a4ed54-2ac4-4b97-b98e-c3fdcaeefd00%7D"><FontAwesomeIcon icon={faBitbucket}  /></a>
          <a href="https://www.pinterest.com/vasudevansuriyababu"><FontAwesomeIcon icon={faPinterest}  /></a>
          <a href="https://www.yelp.com/user_details?userid=KEhJSMyARsAyMWdyD_5b8Q"><FontAwesomeIcon icon={faYelp}  /></a>
        </Container>
      </footer>
    )
  }
}

export default Footer;
