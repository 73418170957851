import React from 'react';
import { Container } from 'reactstrap';
import profile from '../profile.json';
import '../Technology.css';

class Technologies extends React.Component {
  render() {
    return <Container>
      {
        <div>
          <br/>
          <br/>
          <table id='technologies'>
            <tbody>
            <tr>
              <td><b>Technology</b></td>
              <td><b>How Long</b></td>
              <td><b>Self Rating</b></td>
            </tr>
            {
              profile.technologies.map(
                function (tech, i) {
                  return <tr key={i}>
                    <td><a href={tech.url}>{tech.item}</a></td>
                    <td>{tech.long}</td>
                    <td>{tech.level}</td>
                  </tr>
                }
              )
            }
            </tbody>
          </table>
        </div>
      }
      </Container>
  }
}

export default Technologies;
